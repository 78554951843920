import { createGlobalStyle } from 'styled-components';
import { media } from 'styles/media';

export const GlobalStyle = createGlobalStyle`
  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html,
  body {
    height: 100%;
    width: 100%;
  }

  body {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 100%;
  }

  #root {
    min-height: 100%;
    min-width: 100%;
  }

  ._container {
    margin-left: auto;
    margin-right: auto;
    max-width: 600px;

    ${media.desktop} {
      max-width: calc(100% - 90px);
    }
    ${media.medium} {
      max-width: calc(100% - 120px);
    }
    ${media.small} {
      max-width: calc(100% - 30px);
    }
  }

  a {
    color: currentColor;
  }

  p {
    margin: 0;
  }
`;
